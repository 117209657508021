import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'

const Modal = (props) => {
  const [displayWinner , setDisplayWinner] = useState(null)
  useEffect(() => {
    if(props.seedDetails.winner === props.seedDetailsOne.id) {
      setDisplayWinner(`${props.seedDetailsOne.name} is Winner`)
    }
    if(props.seedDetails.winner === props.seedDetailsTwo.id) {
      setDisplayWinner(`${props.seedDetailsTwo.name} is Winner`)
    }
    if(props.seedDetails.winner === 0) {
      setDisplayWinner("Winner yet to be decided")
    }
  },[props.seedDetails,props.seedDetailsOne,props.seedDetailsTwo,displayWinner])
  return (
    <>
      <div className="modal fade" id="fixturemodal" tabindex="-1" aria-labelledby="fixtureModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="fixtureModalLabel">{props.rawFixturesData.tournament_name}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className='d-flex justify-content-around'>
                <div className='team-detail1'>
                  <h3 className='text-center'>{props.seedDetailsOne.name}</h3>
                  <h5 className='text-center'> Score : {props.seedDetailsOne.latest_score} </h5>
                  <h4 className='text-center'> {props.seedDetailsOne.team1_scores} </h4>
                </div>
                <h3 className='d-flex align-items-center'>VS</h3>
                <div className='team-detail2'>
                  <h3 className='text-center'>{props.seedDetailsTwo.name}</h3>
                  <h5 className='text-center'> Score : {props.seedDetailsTwo.latest_score} </h5>
                  <h4 className='text-center'> {props.seedDetailsTwo.team2_scores} </h4>
                </div>
              </div>
              <div className='winner'>
                <h5 className='text-center pt-5'>{props.seedDetails.match_scores}</h5>
              </div>
              <div className='winner'>
                {/* <h5 className='text-center pt-5'>{props.seedDetailsOne.score > props.seedDetailsTwo.score ? `${props.seedDetailsOne.name} is Winner` : `${props.seedDetailsTwo.name} is Winner` }</h5> */}
                <h5 className='text-center pt-5'>{displayWinner}</h5>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              {/* <button type="button" className="btn btn-primary">Save changes</button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Modal
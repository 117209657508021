import React from 'react'

const Header = () => {
  return (
    <>
      <div className="nk-header is-light header-color">
        <div className="container-fluid">
          <div className="nk-header-wrap">
            <div className="nk-menu-trigger me-sm-2 d-lg-none">
              <a href="javascript:;" className="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em className="icon ni ni-menu"></em></a>
            </div>
            <div className="nk-header-brand">
              <a href="javascript:;" className="logo-link">
                <img className="logo-light logo-img" src="./images/logos.png" srcSet="./images/logos.png 2x" alt="logo" />
                <img className="logo-dark logo-img" src="./images/logos.png" srcSet="./images/logos.png 2x" alt="logo-dark" />
              </a>
            </div>
            {/* <!-- .nk-header-brand --> */}
            <div className="nk-header-menu ms-auto" data-content="headerNav">
              <div className="nk-header-mobile">
                <div className="nk-header-brand">
                  <a href="javascript:;" className="logo-link">
                    <img className="logo-light logo-img" src="./images/logos.png" srcSet="./images/logos.png 2x" alt="logo" />
                    <img className="logo-dark logo-img" src="./images/logos.png" srcSet="./images/logos.png 2x" alt="logo-dark" />
                  </a>
                </div>
                <div className="nk-menu-trigger me-n2">
                  <a href="javascript:;" className="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em className="icon ni ni-arrow-left"></em></a>
                </div>
              </div>
            </div>
            {/* <!-- .nk-header-menu --> */}
          </div>
          {/* <!-- .nk-header-wrap --> */}
        </div>
        {/* <!-- .container-fliud --> */}
      </div>
    </>
  )
}

export default Header
import axios from "axios";
import { Bracket, Seed, SeedItem, SeedTeam, RenderSeedProps, SeedTime } from "react-brackets";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import moment from 'moment'
import Modal from "./Modal";
import { urlVariable } from "../../Constant";

const Fixtures = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  
  // Raw Fixtures data from API "getApiFixturesData" store in state "rawFixturesData"
  const [rawFixturesData, setRawFixturesData] = useState([]);
  console.log(rawFixturesData)

  // "tabIndex" state for swipeable in mobile responsive
  const [tabIndex, setTabIndex] = useState(0);

  // Onchange Index Handler to take index 
  const handleSwipeChange = (index) => {
    setTabIndex(index);
  };

  // varibale "location" gives details from URL "For Ex : pathname , search , hash , status etc.
  const location = useLocation();
  console.log(location)

  // variable "queryParameters" get the "id" of tournamentid from the url  
  const queryParameters = new URLSearchParams(location.search).get("tournamentid")
  console.log(queryParameters)

  // getFixturesData store the teams and matches data
  const [fixturesData, setFixturesData] = useState([]);
  console.log(fixturesData);

  // "getApiFixturesData" function gets the all details of {fixtures , tournament name , message}
  // API call (get method)
  const getApiFixturesData = async () => {
    try {
      setIsLoading(true)
      const url = `${urlVariable.url}/api/?tournamentid=${queryParameters}&action=matches`
      // const url = "https://techwalnutvaishnavi.elorca.com/config/";
      // const headers = {
      //   "key":"18FDFCEA",
      //   "Content-Type":"application/json",
      //   "Accept":"*/*"
      // }
      const res = await axios.get(url);
      // const res = await axios.get(url,{headers:headers});
      setFixturesData(res.data.fixtures);
      setRawFixturesData(res.data)
      setIsLoading(false)
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  // AFter clicking on particular seed it gets data for that particular match and stores in seedDetails 
  // in "content.js" file 
  const goToNextPage = (match) => {
    props.getSeedDetails(match)
    console.log(match)
  }

  // "CustomSeed" manages "SeedItem" , "SeedTeam" , "Seed"
  const CustomSeed = ({ seed, breakpoint, roundIndex, seedIndex }) => {
    return (
      <Seed className="single-seed" mobileBreakpoint={breakpoint} style={{ fontSize: 12 }}>
        <SeedItem className="seed-item" onClick={() => goToNextPage(seed)} data-bs-toggle="modal" data-bs-target="#fixturemodal">
          <div>
            <SeedTeam className={`seed-team-one ${seed.winner === seed.teams[0]?.id ? 'seed-team-winner' : 'seed-team-looser'}`}>
              <p>{seed.teams[0]?.name || 'NO TEAM '}</p>
              <p><em className={`${seed.winner === seed.teams[0]?.id ? 'pe-2 icon ni ni-check' : ''}`}></em>{seed.teams[0]?.latest_score}</p>
            </SeedTeam>

            <SeedTeam className={`seed-team-two ${seed.winner === seed.teams[1]?.id ? 'seed-team-winner' : 'seed-team-looser'}`}>
              <p>{seed.teams[1]?.name || 'NO TEAM '}</p>
              <p><em className={`${seed.winner === seed.teams[1]?.id ? 'pe-2 icon ni ni-check' : ''}`}></em>{seed.teams[1]?.latest_score}</p>
            </SeedTeam>
            {/* <SeedTime style={{ color: 'black' }}>{'TBD' ? seed.date : moment(seed.date).format('MMM Do YYYY')}</SeedTime> */}
            <SeedTime style={{ color: 'black' }}>{seed.date}</SeedTime>
          </div>
        </SeedItem>
      </Seed>
    );
  };

  useEffect(() => {
    getApiFixturesData()
  }, [])

  return (
    <>
    {
      isLoading ? <div> <h5>Loading ....</h5> </div> : 
      <>
      <div class="nk-block-head nk-block-head-sm">
       <div class="nk-block-between">
         <div class="nk-block-head-content">
           <h5> {rawFixturesData.message} </h5> 
           <h3 class="nk-block-title page-title text-center">{rawFixturesData.tournament_name}</h3>
         </div>
         <div className="update_time">
           <h6>Last Updated : {moment().format('h:mm:ss a')}  </h6>
         </div>
         {/* <!-- .nk-block-head-content --> */}
       </div>
     </div>
     <Bracket
       rounds={fixturesData}
       swipeableProps={{
         enableMouseEvents: true,
         animateHeight: true,
         index: tabIndex,
         onChangeIndex: handleSwipeChange,
       }}
       roundTitleComponent={(title) => {
         return <h5 className="round-name"> {title} </h5>
       }}
       renderSeedComponent={CustomSeed}
     />
      </>
    }
      
      {/* Modal Pop up after clicking on seed */}
      <Modal 
      rawFixturesData={rawFixturesData}
      seedDetails={props.seedDetails} seedDetailsOne={props.seedDetailsOne} seedDetailsTwo={props.seedDetailsTwo} />
    </>
  );
}


export default Fixtures
import React from 'react'
import Header from "./Header";
import Content from './Content';

const Base = (props) => {
  //  let tournament = 23;

  return (
    <>
      <div className="nk-app-root">
        {/* <!-- wrap @s --> */}
        <div className="nk-wrap ">
            {/* <!-- main header @s --> */}
              <Header />
            {/* <!-- main header @e --> */}
            {/* <!-- content @s --> */}
             <Content />
            {/* <!-- content @e --> */}
            {/* <!-- footer @s --> */}

            {/* <!-- footer @e --> */}
        </div>
        {/* <!-- wrap @e --> */}
    </div>
    {/* <!-- app-root @e --> */}
    </>
  )
}

export default Base
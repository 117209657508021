import React from 'react'
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Base from './components/Base/Base';

function App() {
  return (
    <>
    <BrowserRouter>
      <Base />
    </BrowserRouter> 
   </>
  );
}

export default App;
import React from 'react'
import { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Fixtures from '../Pages/Fixtures'

const Content = () => {
  // "seedDetails" stores the complete data of one Seed 
  // {id, teams , match_score, winner}
  const [seedDetails, setSeedDetails] = useState([])
  console.log(seedDetails)

  // "seedDetailsOne" stores the details of Team 1
  const [seedDetailsOne , setSeedDetailsOne] = useState([])

  // "seedDetailsTwo" stores the details of Team 2
  const [seedDetailsTwo , setSeedDetailsTwo] = useState([])

  // "getSeedDetails" function pass in "fixtures.js" and it get the match details on click 
  // and pass as parameters as "data"
  const getSeedDetails = (data) => {
    console.log(data)
    setSeedDetails(data)
    setSeedDetailsOne(data.teams[0])
    setSeedDetailsTwo(data.teams[1])
  }
  return (
    <>
      <div className="nk-content ">
        <div className="container">
          <div className="nk-content-inner">
            <div className="nk-content-body">
            <Fixtures 
            seedDetailsOne={seedDetailsOne} 
            seedDetailsTwo={seedDetailsTwo} 
            getSeedDetails={getSeedDetails} 
            seedDetails={seedDetails} />
            {/* <Routes>
              <Route exact path="" element={<Fixtures seedDetailsOne={seedDetailsOne} seedDetailsTwo={seedDetailsTwo} getSeedDetails={getSeedDetails} seedDetails={seedDetails} />} />
            </Routes> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Content